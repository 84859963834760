import React from "react"

const Footer = () => {
  return (
    <footer className="bg-black text-white pt-12 pb-2">
      <div className="container mx-auto px-4 lg:px-16">
        <div className="py-6">
          <h2 className="text-center font-semibold text-lg pb-12 uppercase">
            Napíšte nám
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-9 pb-6">
            <div className="order-3 md:order-1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2617.728266360141!2d21.236600416205288!3d48.996728398909184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473eed7d347ccc33%3A0x534c631f77cb68b0!2zSmFya292w6EgNTksIDA4MCAwMSBQcmXFoW92!5e0!3m2!1sen!2ssk!4v1613476357359!5m2!1sen!2ssk"
                width="100%"
                height="230"
                className="border-none md:max-w-sm"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
            <div className="order-2 md:order-2">
              <form action="https://formspree.io/f/xgepbzdy" method="POST">
                <fieldset>
                  <input
                    required
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Meno a Priezvisko"
                    className="w-full bg-black border border-white text-white py-2 px-2 mb-3"
                  />
                  <input
                    required
                    id="email"
                    name="email"
                    type="text"
                    placeholder="meno.priezvisko@gmail.com"
                    className="w-full bg-black border border-white text-white py-2 px-2 mb-3"
                  />
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    placeholder="Dobry den, kontaktujem vas ohladom..."
                    required
                    className="w-full bg-black border border-white text-white py-2 px-2 mb-3"
                  ></textarea>
                  <button
                    id="submit"
                    name="submit"
                    className="w-full bg-white border border-black text-black py-2 px-2 mb-3"
                  >
                    Odoslať
                  </button>
                </fieldset>
              </form>
            </div>
            <div className="order-1 md:order-3 lg:text-lg flex justify-center">
              <div className="">
                <p className="pb-1 flex">
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                  <a
                    href="https://goo.gl/maps/Nv1BSiV3x8CQvFYA7"
                    className="pl-3 text-white hover:text-blue-200"
                  >
                    Jarková 59, 08001 Prešov
                  </a>
                </p>
                <p className="pb-1 flex">
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <a
                    href="mailto:jana.pastorokova@gmail.com"
                    className="pl-3 text-white hover:text-blue-200"
                  >
                    jana.pastorokova@gmail.com
                  </a>
                </p>
                <p className="pb-1 flex">
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <a
                    href="tel:+421903602010"
                    className="pl-3 text-white hover:text-blue-200"
                  >
                    +421 903 602 010
                  </a>
                </p>
                <p className="pb-1 flex">
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <a
                    href="tel:+421903637681"
                    className="pl-3 text-white hover:text-blue-200"
                  >
                    +421 903 637 681
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center text-gray-400 text-xs py-6">
          © {new Date().getFullYear()}{" "}
          designed&nbsp;by&nbsp;PETER&nbsp;PASTOROK, made&nbsp;by&nbsp;
          <a href="http://matejferenc.com">MATEJ&nbsp;FERENC</a>.
        </div>
      </div>
    </footer>
  )
}

export default Footer
