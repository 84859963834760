import React from "react"

const Hamburger = ({ onClick, open = false }) => {
  return (
    <button
      className="text-indent-hidden relative w-10 h-10 text-indent-hidden focus:outline-none"
      onClick={onClick}
    >
      <span
        className={`block h-1_5 w-full bg-black absolute transition-all ${
          open ? "top-1/2 transform -translate-y-1/2 rotate-45" : "top-1"
        }`}
      />
      <span
        className={`block h-1_5 w-full bg-black absolute top-1/2 transform -translate-y-1/2 ${
          open ? "hidden" : ""
        } `}
      />
      <span
        className={`block h-1_5 w-full bg-black absolute transition-all ${
          open ? "top-1/2 transform -translate-y-1/2 -rotate-45" : "bottom-1"
        }`}
      />
      Open Menu
    </button>
  )
}

export default Hamburger
