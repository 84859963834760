import React, { useState, useCallback } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Hamburger from "./hamburger"

const Menu = () => {
  const [open, setOpen] = useState(false)
  const handleMenuToggle = useCallback(() => {
    setOpen(prev => !prev)
  }, [])
  return (
    <div className="fixed left-0 top-0 z-30">
      <div className="mt-4 ml-4 absolute z-10 top-0 left-0">
        <Hamburger onClick={handleMenuToggle} open={open} />
      </div>
      <div
        className={`bg-white shadow px-8 pb-10 pt-20 flex flex-col items-center transition-all ${
          open ? "block" : "hidden"
        }`}
      >
        <AnchorLink
          to="/#home"
          title="ÚVOD"
          className="text-2xl md:text-3xl font-semibold py-1 my-1 border-transparent hover:border-black border-b-4"
        />
        <AnchorLink
          to="/#about"
          title="O NÁS"
          className="text-2xl md:text-3xl font-semibold py-1 my-1 border-transparent hover:border-black border-b-4"
        />
        <AnchorLink
          to="/#projects"
          title="PROJEKTY"
          className="text-2xl md:text-3xl font-semibold py-1 my-1 border-transparent hover:border-black border-b-4"
        />
        <AnchorLink
          to="/#contact"
          title="KONTAKT"
          className="text-2xl md:text-3xl font-semibold py-1 my-1 border-transparent hover:border-black border-b-4"
        />
      </div>
    </div>
  )
}

export default Menu
